const axios = require('axios');

const ISSUES_URL = 'https://app.ndp-systemes.fr/website/request/'
// const ISSUES_URL = 'http://localhost:8051/website/request/'

// Sélectionne tous les formulaires ayant la classe .form-contact
document.addEventListener('DOMContentLoaded', function () {
    // Sélectionne les div des messages d'alerte après le chargement de la page
    const successAlert = document.querySelector('.alert-success');
    const errorAlert = document.querySelector('.alert-danger');

    document.getElementById('form-contact').addEventListener('submit', async function (event) {
        event.preventDefault();

        const form = event.target;

        let formData = {}
        const requestType = form.querySelector('#request_type')?.value || ''
        switch (requestType) {
            case 'phonecall':
                formData = {
                    name: form.querySelector('#lastname')?.value || '',
                    phone_number: form.querySelector('#phone')?.value || '',
                };
                break;
            case 'contact':
                formData = {
                    firstname: form.querySelector('#firstname')?.value || '',
                    lastname: form.querySelector('#lastname')?.value || '',
                    phone_number: form.querySelector('#phone')?.value || '',
                    email_address: form.querySelector('#email')?.value || '',
                    company: form.querySelector('#societe')?.value || '',
                    content: form.querySelector('#message')?.value || '',
                };
                break;
            case 'job':
                formData = {
                    firstname: form.querySelector('#firstname')?.value || '',
                    lastname: form.querySelector('#lastname')?.value || '',
                    phone_number: form.querySelector('#phone')?.value || '',
                    email_address: form.querySelector('#email')?.value || '',
                    content: form.querySelector('#motivations')?.value || '',
                    cv: form.querySelector('#cv') ? pond.getFile()?.getFileEncodeBase64String() || '' : '',
                };
                break;
        }

        try {
            // Envoie les données du formulaire
            const response = await axios.post(ISSUES_URL + requestType, formData, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            // Si la requête est un succès, affiche le message de succès et cache celui d'erreur
            if (response.status === 200 && response.data.result === true) {
                successAlert.style.display = 'block';
                errorAlert.style.display = 'none';
            } else {
                successAlert.style.display = 'none';
                errorAlert.style.display = 'block';
            }
        } catch (error) {
            // Gère les erreurs possibles et affiche le message d'erreur
            successAlert.style.display = 'none';
            errorAlert.style.display = 'block';
        }
    });
});

